import React from 'react';
import { Wrapper } from './styles';
import { Person } from './Person';
import { Container } from 'components/common/Container';

export const People: React.FC<{ people: any }> = ({ people }) => {
  return (
    <Container>
      <Wrapper>
        {people.map((person: any) => (
          <Person {...person} />
        ))}
      </Wrapper>
    </Container>
  );
};
