import React from 'react';
import { Picture, Wrapper } from './styles';

interface PersonProps {
  picture: any;
  name: string;
  title: string;
  smOrder: number;
}

export const Person: React.FC<PersonProps> = props => (
  <Wrapper smOrder={props.smOrder}>
    <Picture fixed={props.picture.childImageSharp.fixed} />
    <p>
      <b>{props.name}</b>, {props.title}
    </p>
  </Wrapper>
);
