import React from 'react';
import { Layout, SEO } from 'components/common';
import { Header } from 'components/theme';
import { graphql } from 'gatsby';
import { Intro, People } from 'components/about';

export default ({ data }: any) => {
  const people = data.allMarkdownRemark.edges.map(
    (e: any) => e.node.frontmatter
  );

  return (
    <Layout>
      <SEO title={'Paneau - About'} />
      <Header product="advertising" />
      <Intro />
      <People people={people} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "employees" } } }
      sort: { fields: [frontmatter___lgOrder], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            name
            title
            picture {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            lgOrder
            smOrder
          }
        }
      }
    }
  }
`;
