import styled from 'styled-components';
import Image from 'gatsby-image';

interface WrapperProps {
  smOrder: number;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  min-width: 220px;
  max-width: 300px;
  width: 25%;
  text-align: center;

  @media (max-width: 943px) {
    order: ${props => props.smOrder};
  }
`;

export const Picture = styled(Image)`
  border-radius: 100px;
  width: 200px;
  height: 200px;
`;
