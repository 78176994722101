import { Wrapper, Container } from './styles';
import React from 'react';

export const Intro = () => {
  return (
    <Wrapper>
      <Container>
        <h1>About Us</h1>
        <p>
          Paneau is a team of individuals transforming the out-of-home ad
          experience. We’re building a platform that moves with you, which
          allows for more engaging experiences and worthwhile impressions.
          Whether you’re a large company wanting to strengthen brand recognition
          or a local business looking to drive foot traffic — Paneau will get
          you where you're going.
        </p>
      </Container>
    </Wrapper>
  );
};
