import styled from 'styled-components';
import { Container as CommonContainer } from 'components/common/Container';

export const Wrapper = styled.div`
  padding: 2rem 0 0;
  overflow: visible;
`;

export const Container = styled(CommonContainer)`
  max-width: 1050px;

  h1 {
    text-align: center;
  }
`;
